body {
  background-color: #f9f9f9 !important;
}

.spacingCell {
  padding: 5px 5px 5px 5px !important;
}

.breadcrumb-item a,
.sidebar .nav-link.active .nav-icon {
  color: #f37322 !important;
}

.sidebar .nav-link:hover {
  background-color: #99b5cc !important;
}
.add-scroll {
  overflow: auto;
}

#nprogress .spinner-icon {
  border-top-color: #f37322 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #99b5cc, 0 0 5px #99b5cc !important;
}

#nprogress .bar {
  background: #f37322 !important;
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

/* Table Styles */
.level-width {
  width: 8%;
}

.status-width {
  width: 12%;
}

/* View Campaign Information */
.label-style-custom {
  font-weight: bold;
}

.btn-warning {
  background-color: #ff7418 !important;
  border-color: #ff7418 !important;
  color: white !important;
}

.btn-warning:hover {
  color: rgb(255, 255, 255) !important;
  background: rgba(255, 116, 24, 0.75) !important;
}

.page-link {
  color: #fe7418 !important;
}

.page-item.active .page-link {
  color: #fff !important;
  background-color: #fe7418 !important;
  border-color: #fe7418 !important;
}

.txt-danger {
  color: red;
}

.custom-list-item {
  padding: 3px 9px !important;
}
.custom-block {
  text-align: left !important;
  color: #fe7418 !important;
}

/* Table pointer */
tr {
  cursor: pointer;
}

.custom-link {
  color: white;
}

/* Voucher Codes */
.voucher-codes-wrap {
  overflow: auto;
  max-height: 8em;
}

.custom-static-control {
  overflow-wrap: break-word;
}

/* image upload preview */
.img-container {
  position: relative;
  width: 50%;
}

.uploaded-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 0.3em;
}

.middle-text {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.img-container:hover .uploaded-image {
  opacity: 0.3;
}

.img-container:hover .middle-text {
  opacity: 1;
}

.img-text {
  color: white;
  cursor: pointer;
}

/* Hide input arrows*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Mobile Tables */

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 50%;
  }

  /*Table border */
  .react-bs-container-body {
    border-bottom: 1px solid #c8ced3;
  }

  /* Pagination */
  .react-bs-table-pagination {
    margin: 10px;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 0;
  }

  td:before {
    /* Now like a table header */
    padding-right: 10px;
    white-space: nowrap;
  }

  .my-size-per-page {
    float: right;
    margin-bottom: 9px;
  }

  /* Hide title*/
  .marketplace-campaign-title i {
    display: none;
  }

  .level-width {
    width: 100%;
  }

  .status-width {
    width: 100%;
  }

  /*
  Label the data
  * Unfufilled Table
	*/
  .unfufilled-table td:nth-of-type(1):before {
    content: "Email";
    text-align: left;
    font-weight: bold;
    display: block;
  }
  .unfufilled-table td:nth-of-type(2):before {
    content: "Type";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  .unfufilled-table td:nth-of-type(3):before {
    content: "Meter No./Phone No.";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  .unfufilled-table td:nth-of-type(4):before {
    content: "Amount";
    text-align: left;
    font-weight: bold;
    display: block;
  }
  .unfufilled-table td:nth-of-type(5):before {
    content: "Date";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  .unfufilled-table td:nth-of-type(6):before {
    content: "Error Message";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  /* 
  *
  *Resolved Table
  */
  .resolved-table td:nth-of-type(1):before {
    content: "Admin";
    text-align: left;
    font-weight: bold;
    display: block;
  }
  .resolved-table td:nth-of-type(2):before {
    content: "Type";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  .resolved-table td:nth-of-type(3):before {
    content: "Status";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  .resolved-table td:nth-of-type(4):before {
    content: "Tenant";
    text-align: left;
    font-weight: bold;
    display: block;
  }
  .resolved-table td:nth-of-type(5):before {
    content: "Score";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  .resolved-table td:nth-of-type(6):before {
    content: "Updated";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  /* 
  *
  *Market Place Table
  */
  .market-campaign-table td:nth-of-type(1):before {
    content: "Name";
    text-align: left;
    font-weight: bold;
    display: block;
  }
  .market-campaign-table td:nth-of-type(2):before {
    content: "Display Name";
    text-align: left;
    font-weight: bold;
    display: block;
  }
  .market-campaign-table td:nth-of-type(3):before {
    content: "Description";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  .market-campaign-table td:nth-of-type(4):before {
    content: "Partner Name";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  .market-campaign-table td:nth-of-type(5):before {
    content: "Start";
    text-align: left;
    font-weight: bold;
    display: block;
  }
  .market-campaign-table td:nth-of-type(6):before {
    content: "End";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  .market-campaign-table td:nth-of-type(7):before {
    content: "Status";
    text-align: left;
    font-weight: bold;
    display: block;
  }
  .market-campaign-table td:nth-of-type(8):before {
    content: "Level";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  /* marketplace-partners-table **/

  .marketplace-partners-table td:nth-of-type(1):before {
    content: "Name";
    text-align: left;
    font-weight: bold;
    display: block;
  }
  .marketplace-partners-table td:nth-of-type(2):before {
    content: "Tag";
    text-align: left;
    font-weight: bold;
    display: block;
  }
  .marketplace-partners-table td:nth-of-type(3):before {
    content: "Description";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  .marketplace-partners-table td:nth-of-type(4):before {
    content: "Country";
    text-align: left;
    font-weight: bold;
    display: block;
  }

  .marketplace-partners-table td:nth-of-type(5):before {
    content: "Province";
    text-align: left;
    font-weight: bold;
    display: block;
  }
  .marketplace-partners-table td:nth-of-type(6):before {
    content: "City";
    text-align: left;
    font-weight: bold;
    display: block;
  }
}

.scroll-history-data-modal {
  overflow-y: scroll;
  max-height: 400px;
}

.scroll-history-data {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
}

@media screen and (min-width: 600px) {
  .email-search {
    width: 300px !important;
  }
}

#nf-instructions,
#textarea-termsOfUse {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.css-yk16xz-control {
  border-color: #e4e7ea !important;
}
